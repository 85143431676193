// For information on how to create your own theme, please see:
// http://ionicframework.com/docs/theming/
@import "./buttons";
@import "./inputs";


:root {
  --ion-background-color: #ffffff;
  --ion-color-dark: #2e2e2e;

  --ion-font-family: 'FontRegular';

  --ion-color-secondary: #dd3333;
  --ion-color-secondary-rgb: 221, 51, 51;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #ba1f1f;
  --ion-color-secondary-tint: #e45c5c;
}
