input, select, textarea {
  border: 1px solid #ccc;
  border-radius: 2px;
  background-color: #fff;
  padding: 6px 10px;
  height: 45px;
  margin: 8px 0;
}

form.ng-submitted {
  input, select {
    &.ng-invalid {
      border-color: var(--ion-color-secondary)
    }
  }
}

small {
  &.validation_error {
    margin-top: -5px;
    color: var(--ion-color-secondary);
  }
}
